import email from '../assets/email.png';
import mobile from '../assets/mobile.png';
import cpp from '../assets/cpp.png';
import css from '../assets/css.png';
import flutter from '../assets/flutter.png';
import git from '../assets/git.png';
import html from '../assets/html.png';
import javascript from '../assets/javascript.png';
import node from '../assets/node.png';
import python from '../assets/python.png';
import react from '../assets/react.png';
import redux from '../assets/redux.png';
import sass from '../assets/sass.png';


import avi from '../assets/avi.png';




import circle from '../assets/circle.svg';

import amazon from '../assets/amazon.png';
import skype from '../assets/skype.png';
import spotify from '../assets/spotify.png';

import dotnet from '../assets/dotnet.png';
import htmlcss from '../assets/htmlcss.png';

export default {
  email,
  avi,
  dotnet,
  htmlcss,
  mobile,
  cpp,
  css,
  flutter,
  git,
  html,
  javascript,
  node,
  python,
  react,
  redux,
  sass,
  
 
  circle,
  amazon,
  skype,
  spotify,
};
