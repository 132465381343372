
import React, { useState } from 'react';
import { HiMenuAlt4, HiX } from 'react-icons/hi';
import { motion } from 'framer-motion';
//import Typical from 'react-typical';
//import Typewriter from 'typewriter-effect';

//import { images } from '../../constants';

import './Navbar.scss';
//import Button from 'react-bootstrap/Button';
import ReactTooltip from 'react-tooltip';



const Navbar = () => {
  const [toggle, setToggle] = useState(false);
  const onButtonClick = () => {
    // using Java Script method to get PDF file
    fetch('ak.pdf').then(response => {
        response.blob().then(blob => {
            // Creating new object of PDF file
            const fileURL = window.URL.createObjectURL(blob);
            // Setting various property values
            let alink = document.createElement('a');
            alink.href = fileURL;
            alink.download = 'Avishek Karki CV.pdf';
            alink.click();
        })
    })
}

  
  return (
    <nav className="app__navbar">
      <div className="app__navbar-logo">
        {/* <img src={images.logo} alt="logo" /> */}
        {/* Port{' '}
        <Typical
        //loop={infinity}
        //wrapper="b"
        steps={[
          'folio',
          1000
        ]}
        /> */}
        
        <div className="app__navbar-title" onClick={() => window.location.reload(true)}>

        {""}<text>Portfolio.</text>
          
        {/* <Typewriter 
        onInit={(typewriter) => {
          typewriter.typeString("Karki")
          /* .callFunction(() => {
            alert("How are you doing?")
          }) 
          .start()
          .pauseFor(2000)
          .deleteAll()
          .typeString("Portfolio");
          
        } 
        }/> */}
        
        </div>
        
      </div>
      
      <ul className="app__navbar-links">
        {['home', 'about', 'work', 'skills', 'contact'].map((item) => (
          <li className="app__flex p-text" key={`link-${item}`}>
            <div />
            <a href={`#${item}`}>{item}</a>
            
          </li>
          
        ))} 
      
        <>
        <button style={{cursor:"Pointer", position: "absolute", right:"40px",}} type="button"  
        data-tip="Download CV" data-type="warning" data-place="left" onClick={onButtonClick}>
          🔰 
        </button> 
        <ReactTooltip />    
        </>      

      </ul>
      
      <div className="app__navbar-menu">
        <HiMenuAlt4 onClick={() => setToggle(true)} />

        {toggle && (
          <motion.div
            whileInView={{ x: [300, 0] }}
            transition={{ duration: 0.85, ease: 'easeOut' }}
          >
            <HiX onClick={() => setToggle(false)} />
            <ul>
              {['home', 'about', 'work', 'skills', 'contact'].map((item) => (
                <li key={item}>
                  <a href={`#${item}`} onClick={() => setToggle(false)}>
                    {item}
                  </a>
                </li>
              ))}
              
              <button style={{cursor:"Pointer", position: "relative", left:"17px"}} type="button" 
              title="Download CV"  data-type="warning" data-place="bottom" onClick={onButtonClick}>
                  🔰 
              </button>
              <ReactTooltip />
            </ul>
            
            
          </motion.div>
        )}
      </div>
     
      
    </nav>
  );
};


export default Navbar;