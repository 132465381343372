import React from 'react';
import { BsTwitter, BsInstagram } from 'react-icons/bs';
import { FaFacebookF } from 'react-icons/fa';
import { BsLinkedin } from 'react-icons/bs';


const SocialMedia = () => (
  <div className="app__social">
    {/* <div>
      <a href='https://twitter.com/mind_matics' target= '_blank' title='twitter'>
      <BsTwitter />
      </a>
    </div> */}
    <div title='facebook'>
    <a href='https://www.facebook.com/avishek.karki.79' target= '_blank' >
      <FaFacebookF />
      </a>
    </div>
    <div title='instagram'>
    <a href='https://www.instagram.com/avi_karkii/' target= '_blank' >
      <BsInstagram />
      </a>
    </div>
    <div title='linkedin'>
    <a href='https://www.linkedin.com/in/avishek-karki-81b396216' target= '_blank' >
      <BsLinkedin />
      </a>
    </div>
  </div>
);

export default SocialMedia;

